<template lang="pug">
.verse.mt-4
  v-layout.mb-3(v-if="!(surah.name_display !== 'Al-Fatihah' && row.verse.id === 1)")
    .number.mr-3.pointer(v-if="getVerseEndImage(row.verse)" align="center")
      v-icon(color="primary" size="38") $icon-surah-number
      div.caption.mt-n7.font-weight-bold.textBlack--text(style="letter-spacing:0.1em;") {{ row.verse.verse_number }}
    .play-button.mr-3.mt-2.pointer
      v-icon(v-if="playingVerse.id === row.verse.id" color="red" @click.native="stopVerse") mdi-stop-circle
      v-icon(v-else @click.native="playVerse(row.verse)") mdi-play-circle
    .bookmark-button.mr-3.mt-2.pointer
      v-icon(v-if="bookmarkedVerse.id === row.verse.id" color="yellow darken-4") mdi-bookmark
      v-icon(v-else @click="bookmarkVerse(row.verse)") mdi-bookmark
  .quran-right.mb-2.mt-8(:style="{'font-size': currentData.fontSize + 'px'}")
    .word.clickable(v-for="(word, index) in row.words" :key="index" :style="{'line-height': currentData.fontSize*2 + 'px'}")
      div.ml-2(v-if="word.text_uthmani && currentData.showTajwid" @click="clickWord(word)")
        span.pointer(v-for="(letter, position) in getLetters(word)" :key="position")
          span(v-if="letter.rule" :style="{ 'color':getRuleColor(letter.rule) }") {{ letter.text }}
          span(v-else) {{ letter.text }}
      span.ml-2(v-else @click="clickWord(word)") {{ word.text_madani }}
  .translation.my-4(v-for="(locale, index) in currentData.translations" :style="{'font-size': (currentData.fontSize * 0.5) + 6 + 'px'}" :key="locale")
    div.ml-2
      span(v-if="getVerseEndImage(row.verse) && index == 0") {{ row.verse.verse_number }}.
      span  {{ row.verse['translation_' + locale] }}
      div.caption.primary--text(v-if="currentData.translations.length > 1") - {{ locale | translationSourceDisplay }}
  v-divider.mb-5
  .modals
    WordDialog(v-if="showWordDialog" :open.sync="showWordDialog" :word="selectedWord")
</template>

<script>
import { mapGetters } from 'vuex'
import { ruleColor, puncColor, puncDetails } from '@/plugins/filters'

export default {
  props: {
    row: {
      type: Object,
      default: null
    },
    surah: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isPlaying: false,
    showWordDialog: false,
    selectedWord: null
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      currentData: 'current/getCurrentData',
      playingVerse: 'verse/getPlayingVerse',
      bookmarkedVerse: 'verse/getBookmarkVerse'
    })
  },
  mounted () {
  },
  methods: {
    getLetters (word) {
      const letters = word.text_madani.split('')
      const rules = word.rules.split(',')
      const datas = []
      for (let i = 0; i < letters.length; i++) {
        const data = {
          text: letters[i],
          rule: rules[i]
        }
        datas.push(data)
      }
      return datas
    },
    getRuleColor (rule) {
      return ruleColor(rule)
    },
    getPuncColor (rule) {
      return puncColor(rule)
    },
    getPuncDetails (rule) {
      return puncDetails(rule)
    },
    getVerseEndImage (verse) {
      if (this.surah.bismillah && verse.id === 1) {
        return null
      }
      return verse.id
    },
    playVerse (verse) {
      this.$store.commit('verse/playVerseById', verse.id)
    },
    stopVerse () {
      this.$store.commit('verse/stopVerse', true)
    },
    bookmarkVerse (verse) {
      this.$store.commit('verse/bookmarkByVerseId', verse.id)
    },
    clickWord (word) {
      this.selectedWord = word
      this.showWordDialog = true
    }
  }
}
</script>

<style scoped>

.pointer {
  cursor: pointer;
}

.translation {
  text-align: left;
}

.word {
  display: inline-block;
}

</style>
