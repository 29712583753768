<template lang="pug">
  v-dialog(fill-height fluid v-model="showModal" fullscreen scrollable transition="fade-transition" persistent)
    v-card
      v-card-title.px-0
        v-layout
          v-btn.my-auto(text depressed @click="showModal = !showModal" rounded)
            v-icon(color="textBlack") mdi-arrow-left
          v-spacer
          div(v-if="!isFetching" align="center")
            div.title.textBlack--text.font-weight-bold {{ surah.name_display }}
            div.body-2.textBlack--text.mt-n2 {{ surah.name_en }}
          v-spacer
          v-btn.my-auto(text depressed rounded)
            v-icon(color="textBlack") mdi-dots-vertical
      v-divider
    
      v-card-text.px-4
        .content.py-2
          v-layout.my-6(v-if="isFetching")
            v-row.fill-height.ma-0(align='center' justify='center')
              v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
          div(v-else-if="rows.length > 0" v-for="(row, index) in rows" :key="index")
            VerseCard(:row="row" :surah="surah")
          div.py-12
            v-layout.px-6(v-if="!isFetching")
              v-btn(v-if="surah.next" depressed outlined rounded small @click="changeSurah(surah.id + 1)")
                v-icon(left) mdi-chevron-left
                | {{ surah.next }}
              v-spacer
              v-btn(v-if="surah.previous" depressed outlined rounded small @click="changeSurah(surah.id - 1)") {{ surah.previous }}
                v-icon(right) mdi-chevron-right

</template>

<script>
import VerseCard from '@/components/VerseCard.vue'

export default {
  name: 'QuranPageDialog',
  props: ['open', 'id'],
  components: { VerseCard },
  data: () => ({
    page: 1,
    isFetching: false,
    surah: {},
    rows: []
  }),
  computed: {
    showModal: {
      get() {
        return this.open
      },
      set(newValue) {
        this.$emit('update:open', newValue)
      }
    },
    surahId: {
      get() {
        return this.id
      },
      set(newValue) {
        this.$emit('update:id', newValue)
      }
    },
  },
  watch: {
    surahId () {
      this.fetchSurah()
    }
  },
  mounted () {
    this.fetchSurah()
  },
  methods: {
    fetchSurah() {
      this.isFetching = true
      this.rows = []
      this.$axios.get(`../database/${this.surahId}.json`)
        .then((resp)=>{
          const verses = resp.data.verses
          verses.forEach((verse) => {
            const line = []
            verse.words.forEach((word) => {
              line.push(word)
            })
            this.rows.push({
              words: line,
              verse
            })
          })
          this.verse = resp.data.surah.bismillah && resp.data.verses[0].id === 1 ? resp.data.verses[1] : resp.data.verses[0]
          this.surah = resp.data.surah
          this.isFetching = false
        })
    },
    changeSurah (id) {
      this.surahId = id
    }
  }
}
</script>
