<template lang="pug">
  .quran(:class="showQuranPageDialog ? 'noscroll' : ''")
    v-container
      div Quran
      .content.py-2
        div(v-for="(surah, index) in surahList" :key="surah.id" @click="openSurah(surah)")
          v-divider.grey.lighten-3.my-2(v-if="index != 0")
          v-row(align="center")
            v-col.shrink(align="center")
              v-icon(color="primary" size="38") $icon-surah-number
              div.caption.mt-n7.font-weight-bold.textBlack--text(style="letter-spacing:0.1em;") {{ surah.id }}
            v-col
              div.title.textBlack--text {{ surah.name_display }}
              div.body-2.textBlack--text.mt-n1 {{ surah.name_en }}
            v-col.shrink
              v-icon(color="textBlack") mdi-chevron-right
    
    .modals
      QuranPageDialog(v-if="showQuranPageDialog" :open.sync="showQuranPageDialog" :id.sync="currentSurahId")

</template>

<script>
import { mapGetters } from 'vuex'
import QuranPageDialog from '@/components/dialogs/QuranPageDialog.vue'

export default {
  name: 'Quran',
  components: { QuranPageDialog },
  data: () => ({
    scrollPosition: 0,
    options: {
      duration: 0,
      offset: 0,
      easing: 'linear'
    },
    showQuranPageDialog: false,
    currentSurahId: 1
  }),
  computed: {
    ...mapGetters({
      surahList: 'verse/getSurahList'
    })
  },
  watch: {
    showQuranPageDialog() {
      this.$vuetify.goTo(this.scrollPosition, this.options)
    }
  },
  activated () {
    this.$vuetify.goTo(this.scrollPosition, this.options)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    onScroll() {
      if (!this.$route.path.includes('/quran') || this.showQuranPageDialog) {
        return
      }
      this.scrollPosition = window.top.scrollY
    },
    openSurah(surah) {
      this.currentSurahId = surah.id
      this.showQuranPageDialog = true
    }
  }
}
</script>

<style scoped>
.noscroll {
  touch-action: none;
}
</style>
